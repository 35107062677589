import React, { useEffect, useRef, useState } from 'react'
import Head from 'next/head'
import SALink from 'components/SALink'
import useIntersectionObserver from 'hooks/intersectionObserver'
import type { Impression } from 'lib/dataLayer'
import DataLayer from 'lib/dataLayer'
import type { ImpressionOld } from 'lib/dataLayerOld'
import DataLayerOld from 'lib/dataLayerOld'
import { getDataLayerItemVariant } from 'lib/helpers'
import { ReservedBadge } from 'styles/algolia/artwork/info'
import { getCleanCategory } from 'components/Artworks/Info/helpers'
import AddToCart, { gaAddToCartWhitelist } from 'components/Artworks/Actions/AddToCart'
import { convertFromCentsToDollars } from 'components/FormatPrice/helpers'
import { getImageAltText, getPrintAltText } from 'components/Artworks/Image/helpers'
import Favorite from '../Actions/Favorites/Action'
import AddToCollection from '../Actions/AddToCollection'
import { ArtworkImage, MinusCircleIcon, CheckCircleIcon } from './styles'
import type { BasicProps } from './Types'
import './Types'
import EditArtwork from '../Actions/EditArtwork'
export const showOverlayTimer = (callback: (...args: Array<any>) => any) => {
  setTimeout(() => {
    callback()
  }, 5000)
}
const DEFAULT_MESSAGE = {
  shouldShowMessage: false,
  action: '',
}

const Component = (props: BasicProps): React.ReactElement<React.ComponentProps<any>, any> => {
  const ecommerceRef: Record<string, any> = useRef(null)
  const {
    shouldOpenNewDesktopPdpTab,
    artistName,
    artworkId,
    category,
    dataApi,
    dataList,
    dataListId,
    dataListName,
    dataPosition,
    eventType,
    hasPrints,
    height,
    href,
    lazyLoad,
    onClick,
    originalStatus,
    priority,
    listPrice,
    rel,
    sku,
    src,
    subject,
    styles,
    title,
    width,
    shouldShowEditIcon,
  } = props
  // We need a singular category to display in hover
  const cleanCategory = getCleanCategory(category)
  const { hasViewed } = useIntersectionObserver(ecommerceRef)
  useEffect(() => {
    if (hasViewed) {
      // Delete this dataLayer event in July as part of universal analytics sunset
      const impressionOld: ImpressionOld = {
        // Strip trailing `s` - Paintings => Painting
        // Change Mixed Media => Mixedmedia
        category: category.replace(/s$/, '').replace(/\sM/, 'm'),
        id: sku,
        list: dataList,
        name: title,
        position: dataPosition,
      }
      DataLayerOld.addImpressionEventOld(impressionOld)
      // This is the new dataLayer event for GA4
      const impression: Impression = {
        // Strip trailing `s` - Paintings => Painting
        // Change Mixed Media => Mixedmedia
        item_category: category.replace(/s$/, '').replace(/\sM/, 'm'),
        item_id: sku || '',
        item_art_id: artworkId || '',
        // item_list_id: dataListId || '',
        // item_list_name: dataListName || '',
        item_name: title || '',
        item_brand: artistName || '',
        item_variant: getDataLayerItemVariant(sku),
        index: dataPosition,
        affiliation: 'Online Store',
        price: convertFromCentsToDollars(listPrice) || 0,
        quantity: 1, // original_availability: originalStatus,
      }
      DataLayer.addImpressionEvent(impression, dataListId, dataListName)
    }
  }, [hasViewed])
  const [{ shouldShowMessage: showMessage, action: actionMessage }, handleShowMessage] = useState(
    DEFAULT_MESSAGE
  )
  useEffect(() => {
    if (showMessage) {
      showOverlayTimer(() => {
        handleShowMessage(DEFAULT_MESSAGE)
      })
    }
  }, [showMessage])
  const imageSrc = `${src}-6.jpg`
  // Test - Do NOT lazy load `Sculpture` images
  const ignoreLoading = category === 'Sculpture'
  const altParams = {
    artistName,
    subject,
    styles,
    category,
  }
  return (
    <>
      {priority && (
        /* Preload Images */
        <Head>
          <link
            key={`__nimg-${imageSrc}`}
            rel='preload'
            fetchPriority='high'
            as='image'
            href={imageSrc}
          />
        </Head>
      )}
      <ArtworkImage data-type='artwork-image' ref={ecommerceRef}>
        <SALink
          aria-label={title}
          data-api={dataApi}
          data-id={sku}
          data-name={title}
          data-position={dataPosition}
          data-list={dataList}
          href={href}
          onClick={onClick}
          rel={rel}
          target={shouldOpenNewDesktopPdpTab ? '_blank' : ''}
          title={`${title} ${cleanCategory}`}
        >
          <picture data-type='image'>
            {/* <source type='image/webp' loading='lazy' srcSet={`${src}-23.webp`} /> */}
            <img
              {...(lazyLoad
                ? {
                    ...(ignoreLoading
                      ? {
                          src: imageSrc,
                        }
                      : {
                          className: 'lazyload',
                          'data-src': imageSrc,
                        }),
                    loading: 'lazy',
                  }
                : {
                    src: imageSrc,
                  })}
              width={width}
              height={height}
              alt={hasPrints ? getPrintAltText(altParams) : getImageAltText(altParams)}
              role='presentation'
              itemProp='image'
              data-type='image'
            />
          </picture>
          {originalStatus === 'reserved' && <ReservedBadge>Reserved</ReservedBadge>}
        </SALink>

        <div
          data-type='overlay'
          {...(showMessage
            ? {
                'data-visible': true,
              }
            : {})}
        >
          {/* TODO - Fix logic when PALETTE api is implemented */}
          {actionMessage === 'unfavorite' && (
            <>
              <MinusCircleIcon />
              <p>
                Success!
                <br />
                This artwork has been removed
                <br />
                from your favorites
              </p>
            </>
          )}
          {actionMessage === 'favorite' && (
            <>
              <CheckCircleIcon />
              <p>
                Success!
                <br />
                This artwork has been added
                <br />
                to your favorites
              </p>
            </>
          )}
        </div>

        <div data-type='actions'>
          {shouldShowEditIcon ? <EditArtwork artworkId={artworkId} /> : ''}
          <AddToCollection artworkId={artworkId} />
          {!shouldShowEditIcon ? (
            <Favorite artworkId={artworkId} onSuccess={handleShowMessage} isOverlayIcon />
          ) : (
            ''
          )}
          {!hasPrints && (
            <AddToCart
              productEvent={gaAddToCartWhitelist({
                artistName,
                category,
                listId: dataList,
                price: listPrice,
                sku,
                title,
                dataPosition,
                artworkId,
              })}
              artworkId={artworkId}
              sku={sku}
              status={originalStatus}
              eventType={eventType || 'unknown'}
            />
          )}
        </div>
      </ArtworkImage>
    </>
  )
}

export default Component